import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import toast from 'react-hot-toast'
import axios from 'axios'
import { API_URL } from '../BaseURL'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../FirebaseConfig'
import { useTranslation } from 'react-i18next'

const AddCard = ({ activeModal, setActiveModal, handleAdded, customerId }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [disable, setDisable] = useState(false);
    const { t } = useTranslation();

    const handleAddCard = async () => {
        let customer_id = customerId;
        setDisable(true);
        try {
            const { error, token } = await stripe.createToken(
                elements.getElement(CardNumberElement), {
                customer: customer_id
            });
            if (!error) {
                // console.log(token);
                await axios({
                    method: "post",
                    url: `${API_URL}add-card`,
                    headers: { 'Content-Type': 'application/json' },
                    data: {
                        "customerId": customer_id,
                        token
                    }
                }).then(async (response) => {
                    if (response.data.success) {
                        handleAdded();
                        setDisable(false);
                    }
                }).catch(err => {
                    toast.error(err?.response?.data?.message || "something went wrong", { style: { background: '#333', color: '#fff' } })
                    setDisable(false)
                    // console.log("else", err?.response?.data?.message);
                });
                setDisable(false);
            } else {
                toast.error(error?.message || "something went wrong", { style: { background: '#333', color: '#fff' } })
                setDisable(false)
                // console.log("else", error.message);
            }
        } catch (error) {
            toast.error(error?.message || "Something went wrong", { style: { background: '#333', color: '#fff' } })
            setDisable(false)
            // console.log("else", error.message);
        }
    }
    return (
        <Modal
            className="modal fade wt-confirm-modal"
            id="confirmGuestModal"
            show={activeModal}
            onHide={setActiveModal}
            // backdrop="static"
            keyboard={false}
            size='md'
        >
            <Modal.Body>
                <div class="wt-confirm-pay-modal-content wt-pay-with-card-detail wt-pay-with-form-item">
                    <div class="wt-confirm-pay-guest-area">
                        <h3 class="wt-confirm-pay-modal-title">{t('add_card')}</h3>

                        <div className="row g-0">
                            <div className="col col-12 col-md-12">
                                <div className="wt-pay-with-form-input">
                                    <CardNumberElement
                                        id="cc-number"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col col-6 col-md-6">
                                <div className="wt-pay-with-form-input">
                                    <CardExpiryElement
                                        id="expiry"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col col-6 col-md-6">
                                <div className="wt-pay-with-form-input">
                                    <CardCvcElement
                                        id="cvc"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wt-confirm-pay-modal-btn">
                    <button ton type="button" class="btn btn-success" disabled={disable} onClick={handleAddCard}>{disable ? `${t('processing')}...` : t('add_card')}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddCard
